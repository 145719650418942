import { render, staticRenderFns } from "./FormArea.vue?vue&type=template&id=565e7c7e&"
import script from "./FormArea.vue?vue&type=script&lang=js&"
export * from "./FormArea.vue?vue&type=script&lang=js&"
import style0 from "./FormArea.vue?vue&type=style&index=0&id=565e7c7e&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QSeparator,QScrollArea});
