<template>
  <FieldSelect
    v-model="selected"
    :state="state"
    :label="label"
    :placeholder="placeholder"
    :is-required="isRequired"
    :disabled="disabled"
    :class-label="classLabel"
    :options="options"
  />
</template>

<script>
import FieldSelect from '@/components/forms/FieldSelect'
import moment from '@/components/moment'

export default {
  components: {
    FieldSelect
  },

  props: {
    value: {},

    state: {
      type: Object
    },

    label: {
      type: String
    },

    placeholder: {
      type: String,
      default: 'Escolha'
    },

    isRequired: {
      type: Boolean
    },

    disabled: {
      type: Boolean,
      default: false
    },

    extraField: {},

    classLabel: {
      type: String
    }
  },

  data () {
    return {
      selected: this.value
    }
  },

  computed: {
    options () {
      return [
        { id: 'DDMMYY', label: `Dia/Mês/Ano. Ex: ${moment().format('DD/MM/YY').toUpperCase()}` },
        { id: 'HHH', label: `Hora. Ex: ${moment().format('HH').toUpperCase()}:00` },
        { id: 'HHMM', label: `Hora:Minuto. Ex: ${moment().format('HH:MM').toUpperCase()}` },
        { id: 'MMMYY', label: `Mês/Ano. Ex: ${moment().format('MMM/YY').toUpperCase()}` },
        { id: 'YYYY', label: `Ano. Ex: ${moment().format('YYYY').toUpperCase()}` },
        { id: 'MMM', label: `Mês. Ex: ${moment().format('MMM').toUpperCase()}` },
        { id: 'DDMMYYHHH', label: `Dia/Mês/Ano Hora. Ex: ${moment().format('DD/MM/YY HH').toUpperCase()}:00)` },
        { id: 'DDMMYYHHMM', label: `Dia/Mês/Ano Hora:Minuto. Ex: ${moment().format('DD/MM/YY HH:mm').toUpperCase()})` }
      ]
    }
  },

  watch: {
    selected (selected) {
      if (selected === this.value) return
      this.$emit('input', selected)
    },

    value (value) {
      if (this.selected === value) return
      this.selected = value
    }
  }
}

</script>
