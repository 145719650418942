<template>
  <Treeselect
    v-if="items.length"

    searchable
    search-nested
    open-on-click
    open-on-focus
    clear-on-select
    close-on-select
    disable-branch-nodes

    :multiple="multiple"

    append-to-body

    v-model="selected"
    :disabled="disabled"
    :clearable="!isRequired"
    :max-height="200"
    :placeholder="placeholder"
    :options="items"
    no-results-text="Nada encontrado"
    search-prompt-text="Buscar"
    loading-text="Buscando"
    :match-keys="['search', 'label']"
  />
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: {
    Treeselect
  },

  props: {
    value: {},

    placeholder: {
      type: String,
      default: 'Opções'
    },

    isRequired: {
      type: Boolean
    },

    disabled: {
      type: Boolean,
      default: false
    },

    multiple: {
      type: Boolean,
      default: false
    },

    options: {
      type: Array
    }
  },

  data () {
    return {
      selected: this.toSelected(this.value),
      items: clone(this.options)
    }
  },

  methods: {
    toSelected (val) {
      if (!this.multiple) return val
      if (Array.isArray(val)) return [...val]
      return val ? [val] : []
    }
  },

  watch: {
    selected (selected) {
      const oldSelected = this.toSelected(this.value)
      const newSelected = this.toSelected(selected)
      if (isEqual(oldSelected, newSelected)) return
      this.$emit('input', newSelected)
    },

    value (value) {
      const oldSelected = this.toSelected(this.selected)
      const newSelected = this.toSelected(value)
      if (isEqual(oldSelected, newSelected)) return
      this.selected = newSelected
    },

    options (options) {
      const items = clone(options)
      if (isEqual(items, this.items)) return
      this.items = items
    }
  }
}

const clone = (options) => (
  options.map((option) => {
    const item = { ...option }
    item.search = clearStr(item.label)
    if (item.children) item.children = clone(item.children)
    if (item.children && !item.children.length) delete item.children
    return item
  })
)

const clearStr = (str) => String(str || '').normalize('NFD').replace(/[\u0300-\u036f]/g, '').trim()
const isEqual = (val1, val2) => JSON.stringify(val1) === JSON.stringify(val2)
</script>

<style lang="sass">
  .vue-treeselect
    z-index: 9999 !important
    .vue-treeselect__value-container
      .vue-treeselect__multi-value
        margin-bottom: 0

        .vue-treeselect__multi-value-item-container
          padding-top: 3px
</style>
