<template>
  <div class="column">
    <div class="col-auto row no-wrap items-center justify-end">
      <q-btn
        flat
        @click="onCancel"
        label="Cancelar"
        padding="4px 12px"
        class="col-auto btnCancel q-mr-lg"
        color="primary"
      />

      <q-btn
        unelevated
        color="primary"
        class="col-auto btnSave"
        padding="4px 12px"
        @click="handlerSave"
      >
        <b>Salvar</b>
      </q-btn>
    </div>

    <q-separator
      class="q-my-sm"
      ref="separator"
    />

    <q-scroll-area
      v-if="scrollHeight"
      :style="`height: ${scrollHeight}`"
    >
      <div
        class="row justiyfy-around"
        ref="contentFormPanel"
      >
        <div class="col">
          <FormPanel
            :panel="form"
            v-model="input"
          />
        </div>
      </div>
    </q-scroll-area>
  </div>
</template>

<script>
import { mapFields } from './reduceFields'
import FormPanel from './FormPanel'
import { dom } from 'quasar'

export default {
  components: {
    FormPanel
  },

  props: {
    formHeight: {
      type: Number
    },

    form: {
      type: Object,
      required: true
    },

    value: {
      type: Object
    },

    beforeSave: {
      type: Function
    },

    submitBottom: {
      type: Boolean,
      default: true
    }
  },

  data () {
    const fields = mapFields(this.form)
    const topSeparator = 0
    const input = getMetadata(this.value, fields)

    return {
      input,
      fields,
      topSeparator
    }
  },

  mounted () {
    const vm = this
    vm.asyncValidate = 0
    vm.$nextTick().then(() => {
      vm.topSeparator = dom.offset(vm.$refs.separator.$el).top
    })
  },

  computed: {
    scrollHeight () {
      const vm = this
      if (!vm.topSeparator) return '50vh'
      return `${vm.formHeight - vm.topSeparator - 12 + 50}px`
    }
  },

  methods: {
    async handlerSave () {
      const vm = this
      const payload = clone(vm.input)
      payload.$state = await checkFields(vm.fields, payload)

      if (payload.$state) {
        const errors = Object.keys(payload.$state).reduce((acc, item) => item.error ? ++acc : acc, 0)
        vm.notify(`Por favor! Verifique as validações de cada campo antes de salvar!<br>${errors.length === 1 ? 'Ainda resta 1 campo inválido' : `Ainda restam ${errors.length} campos inválidos`}!`)
        vm.input = payload
        return
      }

      const body = vm.beforeSave ? vm.beforeSave(payload) : payload
      vm.$emit('on-save', JSON.parse(JSON.stringify(body)))
    },

    onCancel () {
      this.$emit('on-cancel')
    },

    notify (message, color = 'negative') {
      this.$q.notify({
        color: color,
        icon: null,
        position: 'center',
        timeout: 7000,
        progress: true,
        html: true,
        message
      })
    }
  },

  watch: {
    input: {
      deep: true,
      handler (inputChanged, oldInput) {
        if (isEqual(inputChanged, oldInput)) return

        const { $state, ...input } = inputChanged
        const inputFields = Object.keys(input)
        const canCheckField = inputFields.length && Object.keys(oldInput).length
        const fieldChanged = canCheckField ? inputFields.find((field) => input[field] !== oldInput[field]) : null

        if (!fieldChanged) {
          const inputDate = Object.assign(input, { $state })
          !isEqual(inputDate, this.value) && this.$emit('input', inputDate)
          return
        }

        const asyncValidate = ++this.asyncValidate
        validate(this.fields, fieldChanged, input).then((error) => {
          if (asyncValidate !== this.asyncValidate) return
          $state[fieldChanged] = { dirty: true, error }
          const inputValidated = { ...this.input, $state }
          !isEqual(inputValidated, this.value) && this.$emit('input', inputValidated)
        })
      }
    },

    value: {
      deep: true,
      handler (value) {
        const input = clone(value)
        if (isEqual(this.input, input)) return
        Object.assign(this, { input })
      }
    }
  }
}

const isEqual = (target, source) => JSON.stringify(target) === JSON.stringify(source)
const clone = (target) => JSON.parse(JSON.stringify(target))

const getMetadata = (metadata, fields) => {
  const input = metadata ? JSON.parse(JSON.stringify(metadata)) : {}
  input.$state = Object.keys(fields).reduce((acc, name) => ({ ...acc, [name]: { dirty: false, error: null } }), {})
  return input
}

const validate = async (fields, name, input) => {
  const value = input[name]
  const field = fields[name]
  if (!field) return null

  let showing = [true, undefined, null].includes(field.checkVisible)
  if (typeof field.checkVisible === 'function') showing = field.checkVisible(input)
  if (!showing) return null

  const rules = Array.isArray(field.rules) ? field.rules : []

  return rules
    .reduce((queue, check) => queue.then(() => check(value)), Promise.resolve())
    .then(() => null)
    .catch((err) => err)
}

const checkFields = async (fields, input) => {
  const state = {}
  let hasError = false

  await Promise.all(
    Object.keys(fields).map(async (field) => {
      const error = await validate(fields, field, input)
      if (error) hasError = true
      state[field] = { error, dirty: true }
    })
  )

  return hasError ? state : null
}

</script>

<style lang="sass">
  .form-render-class
    .footer-sticky
      z-index: 9
      position: -webkit-sticky
      position: sticky
      bottom: 0

    .btnSave
      font-style: normal
      font-weight: 600
      font-size: 0.8em

    .btnCancel
      font-style: normal
      font-weight: normal
      font-size: 0.8em
</style>
