<template>
  <FieldWrapper
    :label="label"
    :state="state"
    :is-required="isRequired"
    :class-label="classLabel"
  >
    <template #after-label>
      <div class="columns">
        <div class="col-auto">
          <div class="row items-center no-wrap">
            <div class="col">
              <template v-for="(serie, index) in series">
                <div
                  class="row items-center q-mb-sm q-pb-sm"
                  :style="{'border-bottom': index < (series.length -1) ? '1px dashed #ddd' : 'none'}"
                  :key="serie.id"
                >
                  <div class="col column">
                    <div class="col row q-pt-xs">
                      <div class="col q-pr-xs">
                        <InputSelect
                          v-model="serie.field"
                          placeholder="Informação"
                          is-required
                          :options="fields"
                        />
                      </div>

                      <div class="col-5">
                        <InputSelect
                          v-model="serie.scale"
                          placeholder="Escala"
                          is-required
                          :options="scales"
                        />
                      </div>
                    </div>

                    <div class="col row q-pt-xs">
                      <div :class="isBar(serie.type) ? 'col-7 q-pr-xs' : 'col'">
                        <InputSelect
                          v-model="serie.type"
                          placeholder="Tipo"
                          is-required
                          :options="listTypes(index)"
                        />
                      </div>

                      <div :class="isBar(serie.type) ? 'col' : 'hidden'">
                        <q-toggle
                          color="primary"
                          v-model="serie.stack"
                          :label="serie.stack ? 'Barra Empilhada' : 'Em colunas'"
                        />
                      </div>
                    </div>
                  </div>

                  <q-btn
                    label="x"
                    outline
                    dense
                    no-caps
                    padding="0 8px"
                    color="negative"
                    class="col-auto q-mt-xs q-ml-xs"
                    @click="removeSerie(index)"
                    v-show="index"
                  />
                </div>
              </template>
              <div
                class="row wrap"
                v-if="canAddMore"
              >
                <div class="col q-pt-xs">
                  <q-btn
                    label="Adicionar dado"
                    no-caps
                    unelevated
                    padding="0 8px"
                    size="md"
                    color="primary"
                    @click="addSerie"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </FieldWrapper>
</template>

<script>
import FieldWrapper from '@/components/forms/FieldWrapper'
import InputSelect from '@/components/forms/InputSelect'
import { uid } from 'quasar'

export default {
  components: {
    InputSelect,
    FieldWrapper
  },

  props: {
    value: {},
    state: {},
    label: {},
    isRequired: {},
    classLabel: {},
    extraField: {},
    extraField2: {}
  },

  data () {
    const vm = this
    const series = toSeries(vm.value)
    const fields = Object.values(mapFields)
    const scales = Object.values(mapScales)
    return { series, fields, scales }
  },

  methods: {
    isBar (serieType) {
      return ['bar', 'barh'].includes(serieType)
    },

    listTypes (index) {
      const vm = this
      const barIdV = mapSerieTypes.bar.id
      const barIdH = mapSerieTypes.barh.id
      const lineId = mapSerieTypes.line.id
      const cmltvId = mapSerieTypes.cumulative.id

      let options = Object.values(mapSerieTypes)
      const hasTimeline = Boolean(vm.extraField)
      const hasGroupSerie = !!vm.groupSerie
      if (hasTimeline || hasGroupSerie) options = options.filter(({ id }) => [barIdV, barIdH, lineId, cmltvId].includes(id))
      if (!index) return options

      const pieId = mapSerieTypes.pie.id
      const doughnutId = mapSerieTypes.doughnut.id
      options = options.filter(({ id }) => ![pieId, doughnutId, cmltvId].includes(id))

      const hasBarH = Boolean(vm.series.find(({ type }) => type === mapSerieTypes.barh.id))
      if (hasBarH) options = options.filter(({ id }) => id !== barIdV)

      const hasBarV = Boolean(vm.series.find(({ type }) => type === mapSerieTypes.bar.id))
      if (hasBarV) options = options.filter(({ id }) => id !== barIdH)

      const hasLines = Boolean(vm.series.find(({ type }) => type === lineId))
      if (hasLines) options = options.filter(({ id }) => id !== lineId)

      const currentType = vm.series.at(index)?.type
      const forceAddType = currentType && !options.find(({ id }) => id === currentType)
      if (forceAddType) options.unshift(mapSerieTypes[currentType])

      return options
    },

    removeSerie (index) {
      if (index <= 0) return

      const vm = this
      const series = Array.isArray(vm.series) ? [...vm.series] : []
      if (!series.length) return

      series.splice(index, 1)
      vm.series = series
    },

    addSerie () {
      const vm = this
      const series = Array.isArray(vm.series) ? [...vm.series] : []

      series.push({
        id: uid(),
        field: null,
        type: null,
        stack: false
      })

      vm.series = series
    }
  },

  computed: {
    canAddMore () {
      const vm = this
      const nextSerie = vm.series.at(-1)

      return (
        vm.listTypes(999).length &&
        nextSerie.field &&
        nextSerie.type
      )
    }
  },

  watch: {
    value: {
      deep: true,
      handler (value) {
        const series = toSeries(value)
        if (isEqual(series, this.series)) return
        this.series = series
      }
    },

    series: {
      deep: true,
      handler (series) {
        const vm = this
        const value = toSeries(series)
        if (isEqual(value, this.value)) return
        vm.$emit('input', value)
      }
    }
  }
}

const toSeries = (val) => {
  let series = Array.isArray(val) ? JSON.parse(JSON.stringify(val)) : []
  if (series.length < 1) series.push({ id: uid(), field: null, type: 'bar', stack: false })

  const pieId = mapSerieTypes.pie.id
  const lineId = mapSerieTypes.line.id
  const doughnutId = mapSerieTypes.doughnut.id
  const cmltvId = mapSerieTypes.cumulative.id
  const barhId = mapSerieTypes.barh.id
  const barId = mapSerieTypes.bar.id

  const hasPie = series[0].type === pieId
  const hasDoughnut = series[0].type === doughnutId
  const hasCumulative = series[0].type === cmltvId
  if (hasPie || hasDoughnut || hasCumulative) series = series.splice(0, 1)

  const lineIndex = series.findIndex(({ type }) => type === lineId)
  if (lineIndex > -1) series = series.filter(({ type }, index) => type !== lineId || lineIndex === index)

  let bIndex = -1
  const barIndex = series.findIndex(({ type }) => type === barId)
  const barhIndex = series.findIndex(({ type }) => type === barhId)
  if (barIndex > -1) bIndex = barIndex
  if (barhIndex > -1) bIndex = barhIndex

  if (barIndex > -1 && barIndex < bIndex) bIndex = barIndex
  if (barhIndex > -1 && barhIndex < bIndex) bIndex = barhIndex

  if (bIndex > -1) {
    series = series.map((item, index) => {
      if (index < bIndex) return item
      if (![barId, barhId].includes(item.type)) return item
      item.type = series[bIndex].type
      return item
    })
  }

  return series
}

const isEqual = (source, target) => (
  JSON.stringify(source) === JSON.stringify(target)
)

const mapScales = {
  left: { id: 'left', label: 'Escala Esquerda' },
  right: { id: 'right', label: 'Escala Direita' },
  invisible1: { id: 'invisible1', label: 'Escala Invisivel 1' },
  invisible2: { id: 'invisible2', label: 'Escala Invisivel 2' },
  invisible3: { id: 'invisible3', label: 'Escala Invisivel 3' }
}

const mapFields = {
  access: { id: 'access', label: 'Quantidade de Acessos' },
  registers: { id: 'registers', label: 'Quatidade de Cadastros' },
  downloads: { id: 'downloads', label: 'Quantidade de Downloads' },
  views: { id: 'views', label: 'Quantidade de Visualizações' },
  favorites: { id: 'favorites', label: 'Quantidade de Favoritos' }
}

const mapSerieTypes = {
  bar: { id: 'bar', label: 'Barra Vetical' },
  barh: { id: 'barh', label: 'Barra Horizontal' },
  line: { id: 'line', label: 'Linhas' },
  cumulative: { id: 'cumulative', label: 'Acumulativo' },
  pie: { id: 'pie', label: 'Pizza' },
  doughnut: { id: 'doughnut', label: 'Circular' }
}
</script>
