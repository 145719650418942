import { render, staticRenderFns } from "./CropLayout.vue?vue&type=template&id=10d169c5&"
import script from "./CropLayout.vue?vue&type=script&lang=js&"
export * from "./CropLayout.vue?vue&type=script&lang=js&"
import style0 from "./CropLayout.vue?vue&type=style&index=0&id=10d169c5&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDrawer,QScrollArea,QBtn,QTooltip,QSeparator});
