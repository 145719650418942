<template>
  <FieldSelect
    v-model="selected"
    :state="state"
    :label="label"
    :placeholder="placeholder"
    :is-required="isRequired"
    :disabled="disabled"
    :class-label="classLabel"
    :options="options"
  />
</template>

<script>
import FieldSelect from '@/components/forms/FieldSelect'

export default {
  components: {
    FieldSelect
  },

  props: {
    value: {},

    state: {
      type: Object
    },

    label: {
      type: String
    },

    placeholder: {
      type: String,
      default: 'Estile de Cores'
    },

    isRequired: {
      type: Boolean
    },

    disabled: {
      type: Boolean,
      default: false
    },

    classLabel: {
      type: String
    }
  },

  data () {
    return {
      selected: this.value,
      options: [
        { id: 'westeros', label: 'Cores Leves' },
        { id: 'shine', label: 'Cores Fortes' },
        { id: 'walden', label: 'Cores Reais' },
        { id: 'vintage', label: 'Antigo' },
        { id: 'wonderland', label: 'Esverdeado' },
        { id: 'macarons', label: 'Azul Leve' },
        { id: 'essos', label: 'Avermelhado' },
        { id: 'infographic', label: 'Infográfico' },
        { id: 'roma', label: 'Romano' }
      ]
    }
  },

  watch: {
    selected (selected) {
      if (selected === this.value) return
      this.$emit('input', selected)
    },

    value (value) {
      if (this.selected === value) return
      this.selected = value
    }
  }
}

</script>
