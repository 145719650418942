<template>
  <q-layout
    view="hHh Lpr lff"
    class="bg-mural mural-font"
  >
    <q-header
      elevated
      class="bg-primary"
    >
      <ToolbarLayout />
    </q-header>

    <MenuLayout
      v-if="isAdmin"
    />

    <q-page-container>
      <router-view />
    </q-page-container>

    <FilterLayout />
    <CropLayout />
    <ChartLayout />
  </q-layout>
</template>

<script>
import MenuLayout from './MenuLayout'
import CropLayout from './CropLayout'
import ChartLayout from './ChartLayout'
import FilterLayout from './FilterLayout'
import ToolbarLayout from './ToolbarLayout'
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    MenuLayout,
    CropLayout,
    ChartLayout,
    FilterLayout,
    ToolbarLayout
  },

  computed: {
    ...mapState({
      session: ({ authStore }) => authStore.session
    }),

    ...mapGetters({
      isAdmin: 'isAdmin'
    })
  }
}

</script>

<style>
.bg-mural {
  background: #F8F7FE
}
</style>
