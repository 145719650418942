import isRequired from '@/components/form/validations/isRequired'
import FieldText from '@/components/forms/FieldText'
import FieldNumber from '@/components/forms/FieldNumber'
import FieldChartView from '@/components/forms/FieldChartView'
import FieldChatGroup from '@/components/forms/FieldChatGroup'
import FieldChartTheme from '@/components/forms/FieldChartTheme'
import FieldChartWidth from '@/components/forms/FieldChartWidth'
import FieldChartSeries from '@/components/forms/FieldChartSeries'
import FieldTimezone from '@/components/forms/FieldTimezone'
import FieldTimeformat from '@/components/forms/FieldTimeformat'

export default () => ({
  panels: [{
    fields: [{
      name: 'title',
      label: 'Título',
      class: 'col-sm-12',
      component: FieldText,
      placeholder: 'Título',
      isRequired: true,
      rules: [isRequired()]
    }, {
      name: 'timeline',
      class: 'col-sm-12',
      isRequired: true,
      component: FieldChartView
    }, {
      name: 'mainSerie',
      label: 'Mostrar dados',
      class: 'col-sm-12',
      isRequired: true,
      component: FieldChatGroup
    }, {
      name: 'groupSerie',
      label: 'Mostrar dados',
      class: 'col-sm-12',
      component: FieldChatGroup
    }, {
      name: 'timeformat',
      label: 'Formato',
      class: 'col-sm-12',
      component: FieldTimeformat,
      checkVisible: ({ timeline }) => Boolean(timeline)
    }, {
      name: 'timezone',
      label: 'Fuso Horário',
      class: 'col-sm-12',
      component: FieldTimezone,
      checkVisible: ({ timeline }) => Boolean(timeline)
    }, {
      name: 'series',
      label: 'Dados',
      class: 'col-sm-12',
      extraValue: 'timeline',
      extraValue2: 'groupSerie',
      component: FieldChartSeries
    }, {
      name: 'theme',
      label: 'Tema de Cores',
      class: 'col-sm-12',
      component: FieldChartTheme,
      isRequired: true
    }, {
      name: 'width',
      label: 'Largura',
      class: 'col-sm-8',
      component: FieldChartWidth,
      isRequired: true
    }, {
      name: 'height',
      label: 'Altura',
      class: 'col-sm-4',
      suffix: 'px',
      component: FieldNumber,
      maxlength: 4,
      isRequired: true
    }]
  }]
})
