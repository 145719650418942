<template>
  <FieldSelect
    v-model="selected"
    :state="state"
    :label="label"
    :placeholder="placeholder"
    :is-required="isRequired"
    :disabled="disabled"
    :class-label="classLabel"
    :options="options"
  />
</template>

<script>
import FieldSelect from '@/components/forms/FieldSelect'

export default {
  components: {
    FieldSelect
  },

  props: {
    value: {},

    state: {
      type: Object
    },

    label: {
      type: String
    },

    placeholder: {
      type: String,
      default: 'Escolha'
    },

    isRequired: {
      type: Boolean
    },

    disabled: {
      type: Boolean,
      default: false
    },

    extraField: {},

    classLabel: {
      type: String
    }
  },

  data () {
    return {
      selected: this.value
    }
  },

  computed: {
    options () {
      return [
        { id: 'degree', label: 'por Grau' },
        { id: 'core', label: 'por Núcleo' },
        { id: 'region', label: 'por Região' },
        { id: 'role', label: 'por Cargo' },
        { id: 'board', label: 'por Conselho' }
      ].filter(({ id }) => this.extraField !== id)
    }
  },

  watch: {
    selected (selected) {
      if (selected === this.value) return
      this.$emit('input', selected)
    },

    value (value) {
      if (this.selected === value) return
      this.selected = value
    }
  }
}

</script>
