<template>
  <q-drawer
    side="right"
    v-model="drawer"
    bordered
    :width="400"
    class="filter-layout"
  >
    <q-scroll-area class="fit">
      <h6
        class="q-my-xs q-py-xs q-px-sm text-center"
        style="font-size: 1.2em"
      >
        Filtro Avançado
        <q-btn
          dense
          size="0.6em"
          flat
          icon="mdi-close"
          color="grey-8"
        >
          <q-tooltip>Fechar Filtro avançado</q-tooltip>
        </q-btn>
      </h6>

      <q-separator />

      <div class="q-pa-sm">
        <div class="column">
          <template v-for="filter in list">
            <CollapseTransition :key="filter.name">
              <div
                class="col-auto q-pa-xs row items-end"
                v-if="selected.includes(filter.name)"
              >
                <div class="col">
                  <component
                    v-model="query[filter.name]"
                    :is="filter.component"
                    :label="filter.label"
                    :debounce="filter.debounce"
                    :extra-name="filter.extraName"
                    :extra-field.sync="query[filter.extraField]"
                    :option-value="filter.optionValue"
                    :option-label="filter.optionLabel"
                    :api-options="filter.apiOptions"
                  />
                </div>

                <q-btn
                  dense
                  size="sm"
                  flat
                  class="col-auto q-mb-sm q-ml-xs"
                  icon="mdi-trash-can-outline"
                  color="negative"
                  @click="removeFilter(filter.name)"
                />
              </div>
            </CollapseTransition>
          </template>

          <CollapseTransition>
            <div
              class="col q-pa-xs row items-end"
              v-if="options.length"
            >
              <div class="col">
                <FieldSelect
                  v-model="add"
                  placeholder="Adicionar filtro por"
                  :options="options"
                />
              </div>

              <q-btn
                dense
                size="sm"
                flat
                class="col-auto q-mb-sm q-ml-xs"
                icon="mdi-trash-can-outline"
                color="white"
              />
            </div>
          </CollapseTransition>
        </div>
      </div>
    </q-scroll-area>
  </q-drawer>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FieldSelect from '@/components/forms/FieldSelect'
import { CollapseTransition } from '@ivanv/vue-collapse-transition'

export default {
  components: {
    FieldSelect,
    CollapseTransition
  },

  data () {
    return {
      query: {},
      add: null,
      selected: [],
      drawer: false
    }
  },

  computed: {
    ...mapState({
      isOpen ({ tableStore }) {
        return Boolean(tableStore.openFilters)
      },

      filters ({ tableStore }) {
        return Array.isArray(tableStore.filters) ? tableStore.filters : []
      },

      stateQuery ({ tableStore }) {
        return tableStore.query ?? {}
      }
    }),

    options () {
      const options = this.filters.map(({ label, name }) => ({ id: name, label }))
      return options.filter(({ id }) => !this.selected.includes(id))
    },

    list () {
      const min = this.selected.length
      const filters = [...this.filters]

      const fils = filters.reduce(
        (acc, { name }, index) => Object.assign(acc, { [name]: acc[name] ?? min + index }),
        this.selected.reduce((acc, name, index) => Object.assign(acc, { [name]: index + 1 }), {})
      )

      return filters.sort(({ name: aname }, { name: bname }) => {
        if (fils[aname] > fils[bname]) return 1
        return fils[aname] < fils[bname] ? -1 : 0
      })
    }
  },

  methods: {
    ...mapActions({
      setQuery: 'tableStore/setQuery'
    }),

    clearAdd () {
      this.$nextTick().then(() => {
        this.add = null
      })
    },

    removeFilter (filterName) {
      const query = { ...this.query }
      const selected = [...this.selected]
      selected.splice(selected.indexOf(filterName), 1)
      delete query[filterName]
      Object.assign(this, { query, selected })
    }
  },

  watch: {
    isOpen (isOpen) {
      if (isOpen === this.drawer) return

      if (!isOpen) {
        this.drawer = isOpen
        return
      }

      const query = { ...this.stateQuery }
      const filters = this.filters.map(({ name }) => name)
      const selected = Object.keys(query).filter((name) => filters.includes(name))
      if (!selected.length) selected.push(filters[0])
      Object.assign(this, { query, selected, drawer: true })
    },

    drawer (drawer) {
      if (this.isOpen === drawer) return
      this.setFilterOpen(drawer)
    },

    add (filterName) {
      if (filterName) this.selected.push(filterName)
      this.clearAdd()
    },

    query: {
      deep: true,
      handler (query) {
        // if (isEqual(query, this.stateQuery)) return
        // query.page = 1
        // this.setQuery(query)
      }
    },

    stateQuery: {
      deep: true,
      handler (stateQuery) {
        // if (isEqual(this.query, stateQuery)) return
        // this.query = { ...stateQuery }
      }
    }
  }
}

// const isEqual = (target, source) => JSON.stringify(target) === JSON.stringify(source)

</script>

<style lang="sass">
  .filter-layout
    .q-drawer.q-drawer--standard
      z-index: 0
</style>
