<template>
  <div class="toolbarLayout">
    <q-toolbar class="toolbar">
      <q-toolbar-title>
        <div class="row items-center">
          <router-link
            :to="linkFeeds"
            class="col-auto row"
          >
            <LogoHeader
              class="col-auto"
            />
          </router-link>
        </div>
      </q-toolbar-title>

      <span
        class="col-auto q-px-sm"
        style="font-size: 0.75em"
      >v{{ version }}</span>

      <q-chip
        square
        color="white"
        text-color="primary"
        style="padding-right: 0"
      >
        <div class="row items-center">
          <div class="col-auto">
            <q-avatar
              v-if="user.picture"
              style="font-size: 2em"
            >
              <q-img
                spinner-color="primary"
                :src="user.picture"
              >
                <template #loading>
                  <q-spinner-ios color="primary" />
                </template>
              </q-img>
              <q-tooltip v-if="cargo">
                {{ cargo }}
              </q-tooltip>
            </q-avatar>
          </div>

          <div class="col q-pr-sm">
            <b>{{ user.name }}</b>
            <q-tooltip v-if="cargo">
              {{ cargo }}
            </q-tooltip>
          </div>

          <div class="col-auto">
            <q-btn
              flat
              round
              padding="0"
              color="primary"
              icon="mdi-exit-to-app"
              @click="handlerLogout"
            >
              <q-tooltip>Fechar meu Mural</q-tooltip>
            </q-btn>
          </div>
        </div>
      </q-chip>
    </q-toolbar>
  </div>
</template>

<script>
import LogoHeader from '@/assets/LogoHeader.svg'
import { mapState } from 'vuex'
import { version } from '../../../../package.json'
import { ROUTE_FEEDS } from '@/modules/feeds/constants'
import { ACTION_SIGN_OUT } from '@/modules/auth/constants'

export default {
  components: {
    LogoHeader
  },

  data () {
    return { version }
  },

  computed: {
    ...mapState({
      user ({ authStore }) {
        const { session } = authStore
        const user = { ...session }
        const names = (user?.name || '').split(' ')
        const firstName = names.shift()
        user.name = names.length ? `${firstName} ${names.pop()}` : firstName
        return user
      }
    }),

    cargo () {
      const grau = this.user['custom:grau']

      if (grau === 'MG') return 'Mestre Geral Representante'
      if (grau === 'MAG') return 'Mestre Assistente Geral'
      if (grau === 'MC') return 'Mestre Central'
      if (grau === 'MAC') return 'Mestre Assistente Central'
      if (grau === 'RP') return 'Representante de Núcleo'

      const colegiado = this.user['custom:colegiado']
      if (colegiado) return colegiado.split(',').join(' - ')

      const cargo = this.user['custom:cargo_nome']
      if (cargo) return cargo

      if (grau === 'QS') return 'Quadro de Sócios'
      if (grau === 'CI') return 'Corpo Instrutivo'
      if (grau === 'CC') return 'Corpo do Conselho'
      if (grau === 'CDC') return 'Corpo do Conselho'
      if (grau === 'QM') return 'Quadro de Mestres'

      return 'Sócio'
    },

    linkFeeds: () => {
      return { name: ROUTE_FEEDS }
    }
  },

  methods: {
    handlerLogout () {
      this.$store.dispatch(ACTION_SIGN_OUT)
    }
  }
}
</script>

<style lang="sass" scoped>
  .toolbarLayout
    .toolbar
      height: 56px
      border-bottom: solid 1px #CFD8DC
</style>
