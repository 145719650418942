<template>
  <q-drawer
    v-model="drawer"
    show-if-above

    :mini="miniState"
    @mouseover="miniState = false"
    @mouseout="miniState = true"

    :width="200"
    :breakpoint="500"
    bordered
    content-class="bg-white"
  >
    <q-scroll-area class="fit">
      <q-list padding>
        <template v-for="(menu, index) in menus">
          <q-separator
            v-if="index"
            :key="`separator-${index}`"
          />

          <q-item
            :key="`item-${index}`"
            clickable
            v-ripple
            dense
            :to="menu.link ? { name: menu.link } : undefined"
            class="q-py-sm"
          >
            <q-item-section avatar>
              <q-icon :name="menu.icon" />
            </q-item-section>

            <q-item-section>
              {{ menu.label }}
            </q-item-section>
          </q-item>
        </template>
      </q-list>
    </q-scroll-area>
  </q-drawer>
</template>

<script>
import { ROUTE_FEEDS } from '@/modules/feed/constants'
import { ROUTE_AGENCIES } from '@/modules/agencies/constants'
import { ROUTE_DASHBOARD } from '@/modules/dashboard/constants'
import { ROUTE_SCHEDULES } from '@/modules/schedules/constants'
import { ROUTE_DOCUMENTS } from '@/modules/documents/constants'

export default {
  data () {
    const vm = this
    const isGeneralRole = vm.$store.getters.isGeneralRole

    const menus = []

    if (isGeneralRole) {
      menus.push({ icon: 'mdi-monitor-dashboard', label: 'Relatórios', link: ROUTE_DASHBOARD })
    }

    menus.push(
      { icon: 'mdi-rss', label: 'Divulgação', link: ROUTE_FEEDS },
      { icon: 'mdi-calendar-clock', label: 'Agendamentos', link: ROUTE_SCHEDULES },
      { icon: 'mdi-file-document', label: 'Documentos', link: ROUTE_DOCUMENTS }
    )

    if (isGeneralRole) {
      menus.push({ icon: 'mdi-folder-network-outline', label: 'Orgão Emissor', link: ROUTE_AGENCIES })
    }

    return {
      menus,
      drawer: false,
      miniState: true
    }
  }
}
</script>

<style lang="sass">
  .q-item__section--side
    padding-right: 0 !important

  .q-item__section--avatar
    min-width: 35px
</style>
