<template>
  <q-drawer
    side="right"
    ref="drawer"
    v-model="drawer"
    bordered
    :width="400"
    class="chart-layout"
  >
    <q-scroll-area class="fit">
      <div class="row items-center justify-around">
        <h6
          class="col text-center text-bold"
          style="font-size: 1.2em"
        >
          Configuração
        </h6>
      </div>

      <q-separator />

      <div
        class="q-pa-sm"
        v-if="config"
      >
        <FormArea
          v-model="config"
          :form="chartForm"
          @on-save="onSave"
          @on-cancel="onCancel"
          :form-height="formHeight"
        />
      </div>
    </q-scroll-area>
  </q-drawer>
</template>

<script>
import chartForm from '../chartForm'
import { dom } from 'quasar'
import FormArea from '@/components/form/FormArea'

export default {
  components: {
    FormArea
  },

  data () {
    return {
      drawer: false,
      config: null,
      formHeight: null,
      chartForm: chartForm()
    }
  },

  mounted () {
    const vm = this

    vm.$root.$on('openChartConfig', (config) => {
      if (vm.drawer) vm.onCancel()

      vm.original = clone(config)

      vm.$nextTick().then(() => {
        vm.drawer = true

        vm.$nextTick().then(() => {
          vm.formHeight = dom.height(vm.$refs.drawer.$el.children[0])
          vm.config = clone(config)
        })
      })
    })
  },

  methods: {
    onSave (data) {
      const vm = this
      const config = clone(data)
      delete config.$state
      vm.drawer = false
      vm.config = null
      vm.$root.$emit(config.$answerMessage, config)
    },

    onCancel () {
      this.drawer = false
    }
  },

  watch: {
    config: {
      deep: true,
      handler (config, oldConf) {
        if (!config || !oldConf) return
        if (config?.$answerMessage !== oldConf?.$answerMessage) return
        this.$root.$emit(`update-${config?.$answerMessage}`, clone(config))
      }
    },

    drawer: {
      deep: true,
      handler (drawer) {
        if (drawer) return

        const vm = this
        if (!vm.config) return

        const $answerMessage = vm.config.$answerMessage
        vm.config = null
        vm.$root.$emit($answerMessage, vm.original)
      }
    }
  }
}

const clone = (val) => JSON.parse(JSON.stringify(val))
</script>

<style lang="sass">
  .chart-layout
    .q-drawer.q-drawer--standard
      z-index: 0

    .vue-treeselect__control-arrow-container
      display: none
</style>
