<template>
  <FieldWrapper
    :state="state"
    :class-label="classLabel"
  >
    <template #after-label>
      <q-toggle
        color="primary"
        v-model="selected"
        :label="selected ? 'Com Linha do Tempo' : 'Sem Linha do Tempo'"
      />
    </template>
  </FieldWrapper>
</template>

<script>
import FieldWrapper from '@/components/forms/FieldWrapper'

export default {
  components: {
    FieldWrapper
  },

  props: {
    value: {},

    state: {
      type: Object
    },

    disabled: {
      type: Boolean,
      default: false
    },

    classLabel: {
      type: String
    }
  },

  data () {
    return {
      selected: this.value
    }
  },

  watch: {
    selected (selected) {
      if (selected === this.value) return
      this.$emit('input', selected)
    },

    value (value) {
      if (this.selected === value) return
      this.selected = value
    }
  }
}

</script>
