import { render, staticRenderFns } from "./ToolbarLayout.vue?vue&type=template&id=0d228621&scoped=true&"
import script from "./ToolbarLayout.vue?vue&type=script&lang=js&"
export * from "./ToolbarLayout.vue?vue&type=script&lang=js&"
import style0 from "./ToolbarLayout.vue?vue&type=style&index=0&id=0d228621&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d228621",
  null
  
)

export default component.exports
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QSpinnerIos from 'quasar/src/components/spinner/QSpinnerIos.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QToolbar,QToolbarTitle,QChip,QAvatar,QImg,QSpinnerIos,QTooltip,QBtn});
