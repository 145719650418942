<template>
  <q-drawer
    side="right"
    v-model="drawer"
    bordered
    :width="700"
    class="filter-layout"
  >
    <q-scroll-area class="fit">
      <div class="row item-center justify-around">
        <h6
          class="col text-center"
          style="font-size: 1.2em"
        >
          Recortar Imagem
        </h6>

        <div class="col-auto q-pt-md q-pr-md">
          <q-btn
            unelevated
            size="0.8em"
            padding="2px 4px"
            icon="mdi-cloud-upload-outline"
            color="primary"
            @click="uploadCrops"
          >
            <q-tooltip>Salvar</q-tooltip>
          </q-btn>
        </div>

        <div class="col-auto q-pt-md q-pr-lg">
          <q-btn
            padding="2px 4px"
            unelevated
            size="0.8em"
            icon="mdi-close"
            color="negative"
            @click="close"
          >
            <q-tooltip>Fechar</q-tooltip>
          </q-btn>
        </div>
      </div>

      <q-separator />

      <div class="q-pa-sm">
        <div
          class="column"
          v-if="urlFile"
        >
          <div
            class="col column items-center"
            v-for="size in sizes"
            :key="size.caption"
          >
            <div :class="`col-auto my-cropper ${size.ready ? '' : 'cropper-img-hidden'}`">
              <vue-cropper
                v-once
                :ref="size.caption"
                :container-style="{width: `${size.data.width}px`, height: `${size.data.height}px`}"
                :min-container-height="size.data.height"
                :min-container-width="size.data.width"
                :src="urlFile"
                :view-mode="3"
                drag-mode="none"
                :auto-crop-area="1"
                :restore="false"
                :modal="false"
                :guides="false"
                :highlight="false"
                :crop-box-movable="true"
                :crop-box-resizable="false"
                :toggle-drag-mode-on-dblclick="false"
                @ready="onReady(size)"
              />
            </div>
            <div
              class="col-auto row full-width justify-center q-mb-lg q-pb-md"
              style="border-bottom: 1px solid #ddd"
            >
              <q-btn
                flat
                unelevated
                padding="0"
                class="col-auto q-my-sm q-mx-xs"
                icon="mdi-magnify-plus-outline"
                size="1.2em"
                color="primary"
                @click="cropZoomAdd(size)"
              />
              <q-btn
                flat
                unelevated
                padding="0"
                class="col-auto q-my-sm q-mx-xs"
                icon="mdi-magnify-minus-outline"
                size="1.2em"
                color="primary"
                @click="cropZoomRemove(size)"
              />
              <q-btn
                flat
                unelevated
                padding="0"
                class="col-auto q-my-sm q-mx-xs"
                icon="mdi-arrow-left-bold-box-outline"
                size="1.2em"
                color="primary"
                @click="cropLeft(size)"
              />
              <q-btn
                flat
                unelevated
                padding="0"
                class="col-auto q-my-sm q-mx-xs"
                icon="mdi-arrow-right-bold-box-outline"
                size="1.2em"
                color="primary"
                @click="cropRight(size)"
              />
              <q-btn
                flat
                unelevated
                padding="0"
                class="col-auto q-my-sm q-mx-xs"
                icon="mdi-arrow-up-bold-box-outline"
                size="1.2em"
                color="primary"
                @click="cropUp(size)"
              />
              <q-btn
                flat
                unelevated
                padding="0"
                class="col-auto q-my-sm q-mx-xs"
                icon="mdi-arrow-down-bold-box-outline"
                size="1.2em"
                color="primary"
                @click="cropDown(size)"
              />
              <q-btn
                flat
                unelevated
                padding="0"
                class="col-auto q-my-sm q-mx-xs"
                icon="mdi-restore"
                size="1.2em"
                color="primary"
                @click="cropRotateAdd(size)"
              />
              <q-btn
                flat
                unelevated
                padding="0"
                class="col-auto q-my-sm q-mx-xs"
                icon="mdi-reload"
                size="1.2em"
                color="primary"
                @click="cropRotateRemove(size)"
              />
              <q-btn
                flat
                unelevated
                padding="0"
                class="col-auto q-my-sm q-mx-xs"
                icon="mdi-horizontal-rotate-counterclockwise"
                size="1.2em"
                color="primary"
                @click="cropFlipY(size)"
              />
              <q-btn
                flat
                unelevated
                padding="0"
                class="col-auto q-my-sm q-mx-xs"
                icon="mdi-axis-z-rotate-counterclockwise"
                size="1.2em"
                color="primary"
                @click="cropFlipX(size)"
              />
              <q-btn
                flat
                unelevated
                padding="0"
                class="col-auto q-my-sm q-mx-xs"
                icon="mdi-image-filter-center-focus"
                size="1.2em"
                color="primary"
                @click="reset(size)"
              >
                <q-tooltip>Restaurar</q-tooltip>
              </q-btn>
            </div>
          </div>
        </div>
      </div>
    </q-scroll-area>
  </q-drawer>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

const getSize665x150 = () => ({ caption: 'size665x150', data: { width: 665, height: 150 } })
const getSize315x150 = () => ({ caption: 'size315x150', data: { width: 315, height: 150 } })
const getSize150x85 = () => ({ caption: 'size150x85', data: { width: 150, height: 85 } })
const getSize65x85 = () => ({ caption: 'size65x85', data: { width: 65, height: 85 } })

export default {
  components: {
    VueCropper
  },

  data () {
    return {
      drawer: false,
      urlFile: null,
      scaleY: true,
      scaleX: true,
      sizes: [getSize665x150(), getSize315x150(), getSize150x85(), getSize65x85()]
    }
  },

  mounted () {
    const vm = this

    vm.$root.$on('onCrop', ({ resizes, objUrl }) => {
      if (vm.drawer) return vm.close()
      vm.sizes = resizes?.length ? [...resizes] : [getSize665x150(), getSize315x150(), getSize150x85(), getSize65x85()]
      vm.urlFile = objUrl
      vm.drawer = true
    })

    vm.$root.$on('closeCropArea', () => {
      vm.close()
    })
  },

  methods: {
    onReady (size) {
      const vm = this
      const item = { ...size, ready: true }
      const sizes = [...vm.sizes]
      sizes.splice(vm.sizes.indexOf(size), 1, item)
      vm.sizes = sizes
    },

    async uploadCrops () {
      const vm = this
      const sizes = [...vm.sizes]

      for (const size of sizes) {
        const cropData = await vm.getCrop(size)
        Object.assign(size, cropData)
      }

      vm.drawer = false
      vm.urlFile = null

      vm.$root.$emit('onCropResizes', sizes)
    },

    getCrop ({ caption }) {
      const vm = this
      const crp = vm.$refs[caption][0]
      const data = crp.cropper.getData()

      return new Promise((resolve) => (
        crp.cropper.getCroppedCanvas().toBlob((blob) => (
          resolve({ data, blob })
        ))
      ))
    },

    cropZoomAdd ({ caption }) {
      const crp = this.$refs[caption][0]
      crp.relativeZoom(0.2)
    },

    cropZoomRemove ({ caption }) {
      const crp = this.$refs[caption][0]
      crp.relativeZoom(-0.2)
    },

    cropLeft ({ caption }) {
      const crp = this.$refs[caption][0]
      crp.move(-10, 0)
    },

    cropRight ({ caption }) {
      const crp = this.$refs[caption][0]
      crp.move(10, 0)
    },

    cropUp ({ caption }) {
      const crp = this.$refs[caption][0]
      crp.move(0, -10)
    },

    cropDown ({ caption }) {
      const crp = this.$refs[caption][0]
      crp.move(0, 10)
    },

    cropRotateAdd ({ caption }) {
      const crp = this.$refs[caption][0]
      crp.rotate(-45)
    },

    cropRotateRemove ({ caption }) {
      const crp = this.$refs[caption][0]
      crp.rotate(45)
    },

    cropFlipY ({ caption }) {
      const vm = this
      const crp = vm.$refs[caption][0]
      crp.scaleY(vm.scaleY ? -1 : 1)
      vm.scaleY = !vm.scaleY
    },

    cropFlipX ({ caption }) {
      const vm = this
      const crp = vm.$refs[caption][0]
      crp.scaleX(vm.scaleX ? -1 : 1)
      vm.scaleX = !vm.scaleX
    },

    reset ({ caption }) {
      const vm = this
      const crp = vm.$refs[caption][0]
      crp.reset()
    },

    close () {
      const vm = this
      vm.drawer = false
      vm.urlFile = null
      vm.$root.$emit('onCropResizes', [])
    }
  }
}

</script>

<style lang="sass">
  .filter-layout
    .q-drawer.q-drawer--standard
      z-index: 0

    .cropper-img-hidden
      img
        visibility: hidden
</style>
